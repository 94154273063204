/* @font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/IBM_Plex_Sans-normal-400.woff) format('woff');
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/IBM_Plex_Sans-normal-500.woff) format('woff');
  unicode-range: U+0-10FFFF;
} */

@font-face {
  font-family: 'FFUnitWebPro';
  src: url('../fonts/ff-unit-web-pro-regular.woff2') format('woff2'),
    url('../fonts/ff-unit-web-pro-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FFUnitWebPro';
  src: url('../fonts/ff-unit-web-pro-italic.woff2') format('woff2'),
    url('../fonts/ff-unit-web-pro-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'FFUnitWebPro';
  src: url('../fonts/ff-unit-web-pro-bold.woff2') format('woff2'),
    url('../fonts/ff-unit-web-pro-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: 'FFUnitWebPro', sans-serif;
  overflow-y: scroll;
}

.p-component {
  font-family: 'FFUnitWebPro', sans-serif;
}

select option {
  font-family: 'FFUnitWebPro', sans-serif;
}

.p-button {
  background: #e44142;
  border: #e44142;
  padding: 6px 15px;
  border-radius: 2px;
}

.p-button:enabled:hover {
  background: #e44142;
  border: #e44142;
}

.p-button.p-button-outlined {
  color: #e44142;
  border: 1px solid #e44142;
  padding: 6px 15px;
}

.p-button.p-button-outlined:enabled:hover {
  // background: rgba(228, 65, 66, 0.1);
  color: #e44142;
  border: 1px solid #e44142;
  padding: 6px 15px;
}

a.p-button:hover {
  color: white;
  text-decoration: none;
}

.p-inputswitch-slider {
  background: #e44142;
  border: #e44142;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  background: #e44142;
  border: #e44142;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0 0 0 5px;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #90ee90;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 0.1rem;
  border: none;
}

.p-datatable > .p-datatable-wrapper {
  overflow-x: visible !important;
  overflow-y: visible;
}

.p-treetable .p-treetable-tbody > tr > td {
  padding: 0 0 0 5px;
}

.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #90ee90;
}

.p-treetable .p-treetable-thead > tr > th {
  padding: 0.5rem 0.1rem;
  border: none;
}

.p-treetable > .p-treetable-wrapper {
  overflow-x: visible !important;
  overflow-y: visible;
}

.loading-globe {
  width: 70px;
  height: 70px;
  background: url(../img/loading_globe.gif) no-repeat;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  background: #90ee90;
  box-shadow: none;
  color: #000;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #e44142;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background: #e44142;
  opacity: 0.65;
}

.p-inputtext {
  padding: 6px 5px 5px;
  background-color: #f5f5f5;
  border-radius: 2px;
  font: inherit;
}

.p-dropdown {
  background-color: #f5f5f5;
  border-radius: 2px;
}

.p-button.p-button-icon-only {
  padding: 0;
}

.form-group .p-calendar .p-button {
  margin: 0 0 16px;
}

.form-control:focus {
  box-shadow: inset 0 0 0 0.15rem rgba(228, 65, 66, 0.25) !important;
  border-color: #e44142;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 65, 66, 0.25);
  border-color: #e44142;
}

.p-fileupload .p-fileupload-buttonbar {
  padding: 0.5rem;
}
// .p-component.p-inputtext {
//   box-shadow: 5px 5px red !important;
// }

bs-datepicker-container {
  z-index: 2500;
}

.patient-modal .p-radiobutton-label {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.content-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
/*
 * Body Font Style
 */
body {
  font-family: 'FFUnitWebPro';
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
 * Main Font Sizes
 */
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 0;
  margin-bottom: 0;
}

h1,
.h1 {
  font-size: 28px;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  h1,
  .h1 {
    font-size: 32px;
    line-height: 38px;
  }
}

@media screen and (min-width: 1024px) {
  h1,
  .h1 {
    font-size: 32px;
    line-height: 38px;
  }
}

@media screen and (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 40px;
    line-height: 50px;
  }
}

h2,
.h2 {
  font-size: 28px;
  line-height: 38px;
}

@media screen and (min-width: 768px) {
  h2,
  .h2 {
    font-size: 32px;
    line-height: 38px;
  }
}

@media screen and (min-width: 1024px) {
  h2,
  .h2 {
    font-size: 32px;
    line-height: 38px;
  }
}

@media screen and (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 32px;
    line-height: 38px;
  }
}

h3,
.h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 26px;
}

@media screen and (min-width: 768px) {
  h3,
  .h3 {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1024px) {
  h3,
  .h3 {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 20px;
    line-height: 24px;
  }
}

h4,
.h4 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  h4,
  .h4 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1024px) {
  h4,
  .h4 {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 20px;
    line-height: 24px;
  }
}

h5,
.h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin: 0;
}

@media screen and (min-width: 768px) {
  h5,
  .h5 {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (min-width: 1024px) {
  h5,
  .h5 {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (min-width: 1200px) {
  h5,
  .h5 {
    font-size: 14px;
    line-height: 18px;
  }
}

p,
ul,
ol,
dl {
  font-size: 18px;
  line-height: 22px;
}

@media screen and (min-width: 991px) {
  p,
  ul,
  ol,
  dl {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (min-width: 1024px) {
  p,
  ul,
  ol,
  dl {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (min-width: 1200px) {
  p,
  ul,
  ol,
  dl {
    font-size: 14px;
    line-height: 18px;
  }
}

p.lead,
ul.lead,
ol.lead,
dl.lead {
  font-size: 18px;
  line-height: 22px;
}

@media screen and (min-width: 768px) {
  p.lead,
  ul.lead,
  ol.lead,
  dl.lead {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (min-width: 1024px) {
  p.lead,
  ul.lead,
  ol.lead,
  dl.lead {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (min-width: 1200px) {
  p.lead,
  ul.lead,
  ol.lead,
  dl.lead {
    font-size: 16px;
    line-height: 20px;
  }
}

p a {
  color: #e44142;
}

p a:hover {
  text-decoration: none;
  color: #000000;
}

a.link:after {
  content: '\203a';
  font-size: 21px;
  font-weight: 700;
  line-height: 21px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  color: #e23d40;
  vertical-align: -0.02em;
  margin-left: 5px;
  display: inline-block;
}

@media (min-width: 768px) {
  a.link:after {
    font-size: 15px;
  }
}

.archimed-table {
  table {
    margin-bottom: 1rem;
  }

  tbody {
    border-radius: 2px;
    border: 1px solid #dadada;
  }

  table tbody td {
    border-bottom: 1px solid #dadada;
    color: #333;
    font-size: 14px;
    height: 40px;
    position: relative;
    padding: 3px 10px;
  }

  table tbody tr:nth-child(2n) {
    background: #fafafa;
  }

  table tbody tr td .holder-select-btn {
    display: none;
    position: absolute;
    width: max-content;
    padding: 0 10px;
    z-index: 2;
    left: -1rem;
  }

  table tbody tr:hover .holder-select-btn {
    display: inline-block;
  }

  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):hover {
    background: rgba(228, 65, 66, 0.1);
  }

  .p-paginator .p-paginator-element,
  .p-paginator .p-paginator-page,
  .p-paginator .p-paginator-pages .p-paginator-element,
  .p-paginator .p-paginator-pages .p-paginator-page {
    color: #e44142;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #e44142;
    border-color: #e44142;
    color: #ffffff;
  }

  @media (max-width: 991px) {
    table tr {
      padding: 10px;
      display: block;
      border-bottom: 1px solid #dadada;
    }

    table tr td {
      height: auto;
      border: none !important;
    }

    tbody {
      border-radius: 2px;
      border: none;
    }

    table tbody tr td .holder-select-btn {
      left: 30%;
    }
  }
}
////////

.search {
  max-width: 270px;
  display: inline-block;
}
.search.search-physician {
  max-width: 100%;
}
.search.search-patient {
  max-width: 490px;
}
.search.search-physician .select {
  width: 300px;
  display: inline-block;
  margin: 0;
}
.search.search-physician select {
  background: #fff;
}

.search.search-physician input[type='text'],
.search.search-patient input[type='text'] {
  width: 164px;
}
.search .holder-input {
  border-radius: 2px;
  border: 1px solid #dadada;
  position: relative;
}
.search .holder-input input[type='text']::-webkit-input-placeholder {
  color: #888880;
}
.search .holder-input input[type='text']::-moz-placeholder {
  color: #888880;
}
.search .holder-input input[type='text']:-moz-placeholder {
  color: #888880;
}
.search .holder-input input[type='text']:-ms-input-placeholder {
  color: #888880;
}

.checkbox-form .checkbox {
  margin: 0 0 29px;
  border: 1px solid #dadada;
  border-bottom: none;
  border-radius: 2px;
}
.checkbox-form h3 {
  margin: 0 0 9px;
}
.checkbox-form .title {
  position: relative;
}
.checkbox-form .title a {
  font-size: 24px;
  position: absolute;
  right: 17px;
  top: 0;
  display: none;
}
.checkbox-form .title a:hover,
.checkbox-form .title a:focus {
  text-decoration: none;
}
.checkbox-form .checkbox.active {
  border: none;
  margin: 0 0 29px;
}
.checkbox-form .checkbox.active > .holder-checkbox:not(.active) {
  display: none;
}
.checkbox-form .row-checkbox {
  border-bottom: 1px solid #dadada;
  padding: 8px 75px 9px 12px;
  position: relative;
}
.checkbox-form .row-checkbox > a {
  position: absolute;
  right: 12px;
  top: 4px;
  color: #44bc8d;
  font-size: 24px;
}
.checkbox-form .row-checkbox > a:hover,
.checkbox-form .row-checkbox > a:focus {
  text-decoration: none;
}
.checkbox-form .row-checkbox:nth-child(even) {
  background: #fafafa;
}
.checkbox-form .holder-btn .btn-border {
  padding: 5px 56px 7px;
  margin: 0 16px 0 0;
}
@media (max-width: 767px) {
  .checkbox-form .holder-btn .btn-border {
    padding: 5px 40px 7px;
  }
}
.checkbox-form .holder-btn .btn {
  padding: 5px 56px 7px;
  margin: 0 0 0 0;
  border: solid 1px transparent;
}
@media (max-width: 767px) {
  .checkbox-form .holder-btn .btn {
    padding: 5px 40px 7px;
    float: right;
  }
}
.checkbox-form .holder-btn input[disabled].btn {
  color: #dcdcdc;
  border: solid 1px #dcdcdc;
  background: none;
}
.checkbox-form .holder-checkbox:nth-child(even) .row-checkbox {
  background: #fafafa;
}
.checkbox-form .holder-checkbox.active {
  display: block !important;
}
.checkbox-form .holder-checkbox.active > .row-checkbox {
  border: 1px solid #dadada;
  border-radius: 2px;
}
.checkbox-form .holder-checkbox .row-checkbox.active {
  background: #fff;
}
.checkbox-form .holder-row-checkbox {
  border: 1px solid #dadada;
  border-radius: 2px;
  border-bottom: none;
}
.checkbox-form .holder-row-checkbox.active {
  border: none;
}
.checkbox-form .holder-checkbox.active > .hidden {
  display: block !important;
  padding: 11px 0 0;
}
.checkbox-form .holder-row-checkbox.active > .holder-checkbox:not(.active) {
  display: none;
}
.checkbox-form .holder-checkbox .hidden p strong {
  color: #3a3a3a;
  font-size: 14px;
  font-weight: 700;
}
.checkbox-form .holder-checkbox.active > .hidden.hidden-last {
  padding: 28px 0 0;
}
.checkbox-form .holder-radio {
  border-radius: 2px;
  border: 1px solid #dadada;
  padding: 10px 22px 0 9px;
  margin: 0 0 19px;
}
.checkbox-form .holder-radio .radio {
  margin: 0 0 9px;
}
.checkbox-form .holder-radio p {
  margin: 0 0 6px;
}
.checkbox-form .holder-radio label {
  padding: 0 0 0 31px;
  color: #3a3a3a;
  font-size: 16px;
  font-weight: 400;
  margin: 0 39px 0 0;
}
.checkbox-form .holder-radio label:before {
  width: 24px;
  height: 24px;
}
// .checkbox-form .holder-radio .radio input[type='radio']:checked + label:after {
//   width: 14px;
//   height: 14px;
//   left: 5px;
//   top: 11px;
// }
.checkbox-form .holder-radio input[type='text'] {
  margin: 0;
}
.checkbox-form input[type='text'] {
  width: 100%;
  border-radius: 2px;
  border: 1px solid #dadada;
  font-size: 15px;
  font-weight: 400;
  padding: 7px 12px 9px;
  margin: 8px 0 0;
}
.checkbox-form input[type='text']:focus {
  font-size: 15px;
}
@media (-webkit-min-device-pixel-ratio: 0) {
  .checkbox-form input[type='text']:focus,
  .checkbox-form input[type='text'] {
    font-size: 15px;
  }
}
.checkbox-form .holder-radio .row-bottom {
  border-top: 1px solid #dadada;
  background: #fafafa;
  margin: 20px -22px 0 -9px;
  padding: 6px 8px 5px;
}
.checkbox-form .holder-radio .row-bottom .radio {
  margin: 0;
}

form .row input:not([type='checkbox']),
form .row textarea {
  width: 100%;
  padding: 6px 5px 5px;
  border-radius: 2px;
  border: 1px solid #dadada;
  background-color: #f5f5f5;
  margin: 0 0 16px;
  display: block;
  font-size: 16px;
  box-shadow: none;
  height: auto;
}

input[type='checkbox'] {
  display: none;
}
input[type='checkbox']:checked + label:before {
  content: '';
  background: #f5f5f5 url(../img/red-checkmark.svg) no-repeat 50% 50%;
}

.checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  color: #333;
  font-size: 16px;
  padding: 0 0 0 45px;
  margin-bottom: 0;
}
.checkbox label strong {
  font-weight: 700;
  font-style: normal;
}
.checkbox label:before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  margin-top: -12px;
  position: absolute;
  left: 0;
  top: 50%;
  background: #f5f5f5;
  border: #d9d9d9 solid 1px;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: #e44142;
}

.p-dropdown-trigger-icon {
  right: 13px;
  top: 16px;
  position: absolute;
  content: '';
  z-index: 1;
  pointer-events: none;
  color: #db2a33;
  font-size: 8px;
}

.p-toast {
  width: auto !important;
  min-width: 25rem;
  max-width: 90vw;
}
