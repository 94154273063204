/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/dist/css/bootstrap.css';
@import 'primeicons/primeicons.css';
@import 'primeng/resources/primeng.css';
@import 'primeng/resources/themes/bootstrap4-light-blue/theme.css';
@import './assets/css/archimed.scss';
@import './assets/css/general.css';
@import './assets/css/pages.css';
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
// @import './assets/css/header.css';
