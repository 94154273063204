.green-circle {
  width: 18px;
  height: 18px;
  line-height: 18px;
  /*vertical-align: middle;*/
  display: inline-block;
  background: #7bba32;
  border-radius: 100%;
  color: #fff !important;
  text-align: center;
  margin: -3px 0 -3px 3px;
  font-size: 12px;
}
#main {
  position: relative;
  min-height: 100%;
  min-width: 320px;
}
.strong {
  font-weight: 600;
}
#main.active:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.56);
  z-index: 90;
}
#content {
  padding: 0 0 150px;
}
@media (max-width: 991px) {
  #content {
    padding: 0;
  }
}

@media (max-width: 767px) {
  #content > .container {
    padding: 0 20px;
  }
  #content {
    padding-bottom: 0;
  }
}
.holder-icon {
  overflow: hidden;
  margin: 40px 0 0;
  padding: 0 8px 0 0;
}
@media (max-width: 767px) {
  .holder-icon {
    margin: 5px 0 39px;
    padding: 0;
  }
}
.holder-icon .col {
  /*width: 50%;*/ /*now using bootstrap to align columns */
  float: left;
  text-align: center;
  /* padding: 0 25px 0 0; */
  font-size: 16px;
}
@media (max-width: 767px) {
  .holder-icon .col {
    width: auto;
    float: none;
  }
}
/* .holder-icon .col:last-child {
	padding: 0 0 0 25px;
}
@media  (max-width : 767px) {
	.holder-icon .col:last-child {
		padding: 0;
	}
} */
.holder-icon .col figure {
  display: inline-block;
  /*background: #f2f2f2;*/ /* die neuen SVG Icons mit dem runden hellgrauen Hintergrund hauen einem die Augen ein */
  width: 155px;
  height: 155px;
  line-height: 152px;
  border-radius: 100%;
  text-align: center;
  margin: 0 0 42px;
  padding: 0;
}
@media (max-width: 767px) {
  .holder-icon .col figure {
    width: 116px;
    height: 116px;
    margin: 0 0 13px;
  }
  .holder-icon .col figure img {
    width: 60%;
  }
}
.holder-icon .col .green-circle {
  margin: 0 0 0 14px;
}
@media (max-width: 767px) {
  .holder-icon .col .green-circle {
    margin: 0 0 0 6px;
  }
}
/* .holder-icon .col:last-child figure {
	line-height: 165px;
	padding: 0 0 0 8px;
} */
@media (max-width: 767px) {
  .holder-icon .col:last-child figure {
    line-height: 121px;
  }
}
.holder-icon .col .btn {
  display: block;
  margin: 0 0 12px;
}
@media (max-width: 767px) {
  .holder-icon .col .btn {
    margin: 0;
  }
  .holder-icon .col .btn + p {
    /* border: 1px solid #dadada; */
    border-top: none;
    margin: -1px 0 0;
    padding: 8px 0;
    border-radius: 0 0 3px 3px;
  }
}
.patient-info {
  max-width: 752px;
  border-bottom: #dbdbdb solid 1px;
  margin: 32px 0 0;
  padding: 0 0 23px;
}
.patient-info .title {
  overflow: hidden;
  padding: 2px 14px 2px 10px;
  margin: 0 0 3px -10px;
}
.patient-info .title:hover {
  background: #f5f5f5;
}
.patient-info .title:hover a.basket {
  display: block;
}
.patient-info .title h3 {
  margin: 0;
  font-style: normal;
}
.patient-info .title a span {
  float: right;
  margin: 0 0 0 17px;
  font-size: 23px;
}
.patient-info .title a.basket {
  font-size: 23px;
  margin: 0;
  display: none;
}
.patient-info .title a:hover,
.patient-info .title a:focus {
  text-decoration: none;
}
.patient-info p {
  margin: 0 0 2px;
}
.patient-info p span {
  color: #9a9a92;
  font-size: 14px;
  text-transform: uppercase;
}
.patient-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.patient-info ul li {
  margin: 0 0 1px;
}
.patient-info .col-md-6:last-child {
  padding: 0 8px;
}
@media (max-width: 991px) {
  .patient-info .col-md-6:last-child {
    padding: 0 15px;
  }
}
.patient-info .desc {
  padding: 18px 30px 0 0;
}
.postcode-form {
  margin: 0 px 30px;
  padding: 23px 0 0;
}
.postcode-form h3 {
  margin: 0 0 13px;
  font-style: normal;
}
.postcode-form .radio {
  margin: 0 0 9px;
}
.postcode-form .radio label {
  padding-left: 29px;
  margin: 0;
  margin-top: 5px;
  margin-right: 5px;
}
.postcode-form .radio label:before {
  left: 0;
  width: 24px;
  height: 24px;
  bottom: 5px;
}
.postcode-form .radio input[type='radio']:checked + label:after {
  left: 5px;
  top: 12px;
  width: 14px;
  height: 14px;
}

.postcode-form .radio label::before {
  bottom: 5px;
  height: 24px;
  left: 0;
  width: 24px;
}
.postcode-form .radio label:last-child {
  margin: 5px 0 0 20px;
}
@media (max-width: 1200px) {
  .postcode-form .radio label:last-child {
    margin: 0;
  }
}
.postcode-form label {
  font-weight: 400;
  margin: 0 0 15px;
}
/* .postcode-form .postcode {
	margin: 0 -22px 68px 0;
	position: relative;
} */
.postcode-form .postcode .icon-main-red-checkmark {
  margin-top: 10px;
  font-size: 16px;
  color: #7cb744;
  /* display: inline-block !important; */
  /* float: left; */
}
.postcode-form .postcode label {
  color: #000000;
  font-size: 24px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  margin: 8px 10px 0 0;
  float: left;
}

.postcode-form label.takenon {
  margin: 20px 0 5px 0;
  float: left;
}

.postcode-form .postcode input {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  border-radius: 2px;
  border: 1px solid #dadada;
  background-color: #f5f5f5;
  padding: 0;
  text-align: center;
  margin: 0 7px 0 0;
  font-size: 24px;
  color: #000;
  float: left;
}

.postcode-form .postcode-custom input {
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
  border: 1px solid #dadada;
  background-color: #f5f5f5;
  padding: 2px 12px;
  text-align: left;
  margin: 0 7px 0 0;
  font-size: 24px;
  color: #000;
}

.postcode-form .postcode input:focus {
  font-size: 24px;
}
@media (-webkit-min-device-pixel-ratio: 0) {
  .postcode-form .postcode input:focus,
  .postcode-form .postcode input {
    font-size: 24px;
  }
}
@media (max-width: 1200px) {
  .postcode-form .postcode label {
    margin: 0;
  }
  .postcode-form .postcode input {
    margin: 0;
    width: 33px;
  }
}
.postcode-form .postcode input:last-child {
  margin: 0;
}
.postcode-form .col-md-6:first-child {
  padding-right: 29px;
}
.postcode-form .col-md-6:last-child {
  padding-left: 1px;
}
.postcode-form figure {
  margin: 3px 0 0;
}
.postcode-form figure img {
  max-width: 100%;
  height: auto;
}
.postcode-form .holder-btn {
  overflow: hidden;
  margin: 29px 0 0;
}
.postcode-form .holder-btn .btn-border,
.postcode-form .holder-btn .btn {
  padding: 7px 44px 8px 44px;
}
@media (max-width: 1200px) {
  .postcode-form .holder-btn .btn-border,
  .postcode-form .holder-btn .btn {
    padding: 5px 40px 8px;
  }
}
.postcode-form .holder-btn .btn-border:first-child {
  float: left;
}
.postcode-form .holder-btn .btn:last-child {
  float: right;
}

.row-info {
  max-width: 752px;
  border-bottom: 1px solid #dbdbdb;
  margin: 21px 0 0;
  padding: 0 0 20px;
}
.row-info .title {
  margin: 0 0 3px -10px;
  overflow: hidden;
  padding: 2px 14px 2px 10px;
}
.row-info .title h3 {
  margin: 0 0 5px;
}
.row-info .title a {
  float: right;
  font-size: 23px;
  margin: 0 0 0 17px;
}
.row-info .title:hover {
  background: #f5f5f5;
}
.row-info .title:hover a.basket {
  display: block;
}
.row-info .title a:hover,
.row-info .title a:focus {
  text-decoration: none;
}
.row-info .title a.basket {
  font-size: 23px;
  margin: 0;
  display: none;
}
.row-info ul {
  list-style: none;
  padding: 0;
  margin: 0 0 13px;
}
.row-info ul li {
  margin: 0 0 7px;
}
.row-info .list {
  margin: 0 0 27px;
}
.row-info .list li {
  position: relative;
  padding: 0 0 0 15px;
  color: #333;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 16px;
}
.row-info .list li:after {
  position: absolute;
  content: '';
  left: 2px;
  top: 9px;
  width: 6px;
  height: 6px;
  background: #e44142;
  border-radius: 100%;
}
.row-info .list li small {
  color: #333;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin: 0 0 -9px;
}
.row-info p span {
  color: #9e9e97;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
}
.checkbox-form {
  padding: 0;
  margin: 0 -5px 0 0;
}
.checkbox-form .checkbox {
  margin: 0 0 29px;
  border: 1px solid #dadada;
  border-bottom: none;
  border-radius: 2px;
}
.checkbox-form h3 {
  margin: 0 0 9px;
}
.checkbox-form .title {
  position: relative;
}
.checkbox-form .title a {
  font-size: 24px;
  position: absolute;
  right: 17px;
  top: 0;
  display: none;
}
.checkbox-form .title a:hover,
.checkbox-form .title a:focus {
  text-decoration: none;
}
.checkbox-form .checkbox.active {
  border: none;
  margin: 0 0 29px;
}
.checkbox-form .checkbox.active > .holder-checkbox:not(.active) {
  display: none;
}
.checkbox-form .row-checkbox {
  border-bottom: 1px solid #dadada;
  padding: 8px 75px 9px 12px;
  position: relative;
}
.checkbox-form .row-checkbox > a {
  position: absolute;
  right: 12px;
  top: 4px;
  color: #44bc8d;
  font-size: 24px;
}
.checkbox-form .row-checkbox > a:hover,
.checkbox-form .row-checkbox > a:focus {
  text-decoration: none;
}
.checkbox-form .row-checkbox:nth-child(even) {
  background: #fafafa;
}
.checkbox-form .holder-btn .btn-border {
  padding: 5px 56px 7px;
  margin: 0 16px 0 0;
}
@media (max-width: 767px) {
  .checkbox-form .holder-btn .btn-border {
    padding: 5px 40px 7px;
  }
}
.checkbox-form .holder-btn .btn {
  padding: 5px 56px 7px;
  margin: 0 0 0 0;
  border: solid 1px transparent;
}
@media (max-width: 767px) {
  .checkbox-form .holder-btn .btn {
    padding: 5px 40px 7px;
    float: right;
  }
}
.checkbox-form .holder-btn input[disabled].btn {
  color: #dcdcdc;
  border: solid 1px #dcdcdc;
  background: none;
}
.checkbox-form .holder-checkbox:nth-child(even) .row-checkbox {
  background: #fafafa;
}
.checkbox-form .holder-checkbox.active {
  display: block !important;
}
.checkbox-form .holder-checkbox.active > .row-checkbox {
  border: 1px solid #dadada;
  border-radius: 2px;
}
.checkbox-form .holder-checkbox .row-checkbox.active {
  background: #fff;
}
.checkbox-form .holder-row-checkbox {
  border: 1px solid #dadada;
  border-radius: 2px;
  border-bottom: none;
}
.checkbox-form .holder-row-checkbox.active {
  border: none;
}
.checkbox-form .holder-checkbox.active > .hidden {
  display: block !important;
  padding: 11px 0 0;
}
.checkbox-form .holder-row-checkbox.active > .holder-checkbox:not(.active) {
  display: none;
}
.checkbox-form .holder-checkbox .hidden p strong {
  color: #333;
  font-size: 14px;
  font-weight: 700;
}
.checkbox-form .holder-checkbox.active > .hidden.hidden-last {
  padding: 28px 0 0;
}
.checkbox-form .holder-radio {
  border-radius: 2px;
  border: 1px solid #dadada;
  padding: 10px 22px 0 9px;
  margin: 0 0 19px;
}
.checkbox-form .holder-radio .radio {
  margin: 0 0 9px;
}
.checkbox-form .holder-radio p {
  margin: 0 0 6px;
}
.checkbox-form .holder-radio label {
  /*padding: 0 0 0 31px;*/
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin: 3px 20px 5px 0;
}
.checkbox-form .holder-radio label:before {
  width: 24px;
  height: 24px;
}
.checkbox-form .holder-radio .radio input[type='radio']:checked + label:after {
  width: 14px;
  height: 14px;
  left: 8px;
  top: 14px;
}
.checkbox-form .holder-radio input[type='text'] {
  margin: 0;
}
.checkbox-form input[type='text'] {
  width: 100%;
  border-radius: 2px;
  border: 1px solid #dadada;
  font-size: 15px;
  font-weight: 400;
  padding: 7px 12px 9px;
  margin: 8px 0 0;
}
.checkbox-form input[type='text']:focus {
  font-size: 15px;
}
@media (-webkit-min-device-pixel-ratio: 0) {
  .checkbox-form input[type='text']:focus,
  .checkbox-form input[type='text'] {
    font-size: 15px;
  }
}
.checkbox-form .holder-radio .row-bottom {
  border-top: 1px solid #dadada;
  background: #fafafa;
  margin: 20px -22px 0 -9px;
  padding: 6px 8px 5px;
}
.checkbox-form .holder-radio .row-bottom .radio {
  margin: 0;
}
.radio-form {
  padding: 23px 0 0;
  margin: 0 0 114px;
}
.radio-form h3 {
  margin: 0 0 5px;
}
.radio-form .radio {
  margin: 0 0 15px;
}
.radio-form .radio p {
  margin: 0 0 8px;
}
.radio-form .radio label:before {
  width: 24px;
  height: 24px;
  left: 1px;
  bottom: 6px;
}
.radio-form .radio input[type='radio']:checked + label:after {
  width: 14px;
  height: 14px;
  left: 6px;
  top: 11px;
}
.radio-form .radio label {
  padding: 0 0 0 32px;
  margin: 0 46px 0 0;
}
.radio-form .holder-btn {
  border-top: #dbdbdb solid 1px;
  padding: 16px 0 0;
  margin: 13px 0 0;
}
.radio-form .holder-btn .btn-border {
  margin-right: 16px;
  padding: 6px 53px 8px 60px;
}
.radio-form .holder-btn .btn-border {
  margin-right: 16px;
  padding: 6px 53px 8px 60px;
}
@media (max-width: 767px) {
  .radio-form .holder-btn .btn-border {
    padding: 6px 15px 8px 15px;
  }
}
.radio-form .holder-btn .btn {
  padding: 7px 129px 8px 135px;
  border: solid 1px transparent;
}
@media (max-width: 767px) {
  .radio-form .holder-btn .btn {
    padding: 7px 15px 8px 15px;
  }
}
.radio-form .holder-btn input[disabled].btn {
  color: #dcdcdc;
  border: solid 1px #dcdcdc;
  background: none;
}
.radio-form .download {
  overflow: hidden;
}
.radio-form .download figure {
  display: inline-block;
  margin: 0 5px 0 3px;
  vertical-align: middle;
}
.radio-form .download a {
  text-decoration: underline;
  display: inline-block;
  color: #333;
}
.radio-form .download a:hover {
  text-decoration: none;
}
.radio-form .download p {
  margin: 21px 0 0;
}
.review .holder-select {
  text-align: right;
}
@media (max-width: 991px) {
  .review {
    padding: 17px 0 0;
    margin: 0 0 10px;
  }
  .review .holder-select {
    border: 1px solid #dadada;
    border-bottom: none;
    background-color: #f2f2f2;
    margin: -2px 0 0;
    padding: 20px 20px 24px;
    display: none;
  }
  .review .holder-select.active {
    background: #fff;
    border-top-color: transparent;
    padding: 0 20px 17px;
  }
}
.review .holder-select .select {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin-left: 16px;
}
@media (max-width: 991px) {
  .review .holder-select.active .select {
    margin: 0 0 10px;
  }
}
@media (max-width: 767px) {
  .review .holder-select {
    margin: -2px -20px 0;
    border: none;
    border-top: 1px solid #dadada;
  }
}
@media (max-width: 991px) {
  .review .holder-select .select {
    display: block;
    margin: 0 0 17px;
  }
  .review .holder-select .btn {
    margin-top: 19px;
  }
  .review .holder-select .btn.hidden {
    display: none !important;
  }
}
.review .holder-select .select select {
  padding: 7px 40px 6px 11px;
  height: 39px;
  width: 153px;
}
@media (max-width: 991px) {
  .review .holder-select .select select {
    background: #fff;
    width: 100%;
  }
}
.review .holder-select .select:first-child {
  margin-left: 0;
}
.review .holder-select .select:first-child select {
  width: 153px;
}
@media (max-width: 991px) {
  .review .holder-select .select:first-child select {
    width: 100%;
  }
}
.review .holder-select .select span {
  color: #979797;
  right: 18px;
  top: 19px;
  font-size: 6px;
}
@media (max-width: 767px) {
  .review .col-xs-6 {
    padding-right: 5px;
  }
  .review .col-xs-6.visible-xs {
    padding-right: 15px;
    padding-left: 5px;
  }
}
.review table {
  width: 100%;
  margin: 3px 0 100px;
  padding: 1 px 0 0;
}
@media (max-width: 991px) {
  .review table {
    display: block;
    border: 1px solid #dadada;
    border-bottom: none;
    margin: 0 0 50px;
  }
}
@media (max-width: 767px) {
  .review table {
    width: auto;
    border: none;
    border-top: 1px solid #dadada;
  }
}
@media (max-width: 991px) {
  .review table tr {
    display: block;
    overflow: hidden;
    padding: 10px 20px;
    border-bottom: 1px solid #dadada;
    max-height: 75px;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
  }
  .filter-btn.btn {
    border: 1px solid #dadada;
    background-color: #f2f2f2;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    padding: 9px 15px 8px;
    cursor: pointer;
    margin: 0 0 18px;
  }
  .review .search {
    margin: 0 0 18px;
  }
}
@media (max-width: 767px) {
  .review table tr {
    padding: 10px;
  }
  reviews-table .p-col-12 {
    padding: 0;
  }
  /* .review table tbody td:nth-child(4) {
		padding-left: .5em !important;
	} */
}
.review thead th {
  color: #333;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 0 7px 0;
}
.search-cro {
  margin-bottom: 10px;
}
span.or {
  margin: 7px;
  display: inline-block;
}
@media (max-width: 991px) {
  .review thead th,
  .review thead {
    display: none;
  }
}
.review thead th:nth-child(1) {
  width: 27px;
  padding-left: 44px;
}
.review thead th:nth-child(2) {
  width: 125px;
}
.review thead th:nth-child(3) {
  width: 110px;
}
.review thead th:nth-child(4) {
  width: 100px;
}
.review thead th:nth-child(5) {
  width: 104px;
}
.review thead th:nth-child(6) {
  width: 160px;
}
.review thead th:nth-child(7) {
  width: 124px;
}
.review thead th:nth-child(8) {
  width: 111px;
}
.review td:nth-child(3) {
  word-wrap: break-word;
}

.review tbody {
  border-radius: 2px;
  border: 1px solid #dadada;
  border-bottom: none;
}
@media (max-width: 991px) {
  .review tbody {
    display: block;
    border: none;
  }
}
.review table tbody td {
  border-bottom: 1px solid #dadada;
  color: #333;
  font-size: 14px;
  height: 40px;
  position: relative;
  padding: 3px 10px;
}
@media (max-width: 991px) {
  .review table tbody td {
    display: block;
    border: none;
    height: auto;
    padding: 0;
    margin-left: 0;
    margin-top: 2px;
    /* text-transform: uppercase; */
  }
}
.review table tbody td:nth-child(2):not(.p-column-title) {
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 0 0 6px;
  word-wrap: break-word;
}

table tbody td .p-column-title {
  text-transform: uppercase;
  font-weight: 100;
  margin-right: 5px;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

@media (max-width: 991px) {
  .review table tbody td:nth-child(2) {
    margin: 0;
  }
  .review table tbody td:nth-child(3) {
    width: 80%;
    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width: 991px) {
  /* .review table tbody td:nth-child(4) {
		padding-left: 0;
		float: right;
		opacity: 0.5;
		color: #333;
		font-size: 14px;
		font-weight: 400;
		/*margin: -59px 0 0;*/ /* haut repsonsive Ansicht zusammen wenn Template Titel zu lange ist */
  /* } */
  .review table tbody tr.active {
    max-height: 1000px;
    -webkit-transition: 1.5s;
    -moz-transition: 1.5s;
    -o-transition: 1.5s;
    transition: 1.5s;
  }
  /* .review table tbody td:nth-last-child(2) {
		margin: 9px -20px 6px 10px;
		padding: 6px 0 0;
	} */
  /* .review table tbody td:last-child {
		margin: 0 0 4px 0;
	} */
}
.review table tbody tr:nth-child(even) {
  background: #fafafa;
}
.review table tbody td figure {
  width: 27px;
  display: inline-block;
}
@media (max-width: 991px) {
  /* .review table tbody tr:nth-child(even) {
		background: #dfdfdf;
	} */
  .review table tbody td figure {
    width: 24px;
    vertical-align: top;
  }
  .review table tbody td figure img {
    vertical-align: top;
  }
}
.review table tbody td a {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  margin: 0 4px 0 0;
}
.review table tbody td a:hover {
  text-decoration: none;
}
.review table tbody td:last-child img {
  margin-right: 3px;
}
.review table tbody td .icon-main-dropdown-arrow {
  display: none;
}
@media (max-width: 991px) {
  .review table tbody td .icon-main-dropdown-arrow {
    display: block;
    right: 4px;
    top: 4px;
    position: absolute;
    content: '';
    z-index: 1;
    color: #db2a33;
    font-size: 8px;
  }
  .review table tbody tr.active td .icon-main-dropdown-arrow {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
.review .holder-select .select .close {
  display: none;
}
@media (max-width: 991px) {
  .review .holder-select .select.close-active .close {
    display: block;
  }
  .review .holder-select .select.close-active .icon-main-dropdown-arrow {
    display: none;
  }
  .review .holder-select .select .close {
    height: 20px;
    width: 20px;
    opacity: 1;
    right: 12px;
    top: 9px;
    cursor: pointer;
    position: absolute;
    content: '';
    z-index: 1;
  }
  .review .holder-select .select .close:after {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    width: 11px;
    height: 2px;
    margin: 0 0 0 -5px;
    background: #e03e45;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .review .holder-select .select .close:before {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    width: 11px;
    height: 2px;
    margin: 0 0 0 -5px;
    background: #e03e45;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.review .holder-select .link {
  display: none;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.18px;
  margin: 9px 3px 0 0;
}
span.status {
  display: inline-block;
  width: 17px;
  height: 17px;
  border-radius: 2px;
  top: 11px;
  position: absolute;
}
span.status + span {
  padding-left: 27px;
}
span.yes {
  background: #7bba32;
}
span.no {
  background: #db2a33;
}

a.register-now {
  margin: 5px 0 0 10px;
}

div.holder-icon div.col {
  margin-bottom: 40px;
}

.pharmaSponsored {
  background: rgb(83, 89, 165) none repeat scroll 0% 0%;
}
div.search button.btn {
  margin-top: 6px;
}
