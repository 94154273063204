html {
  height: 100%;
}
body {
  color: #333;
  font-family: 'FFUnitWebPro', sans-serif;
  font-size: 16px;
  height: 100%;
  overflow-y: scroll;
}
a {
  cursor: pointer;
}
.pool-container ul {
  padding-left: 14px;
}
.pool-container ul li {
  margin-top: 3px;
}

.modal-header {
  padding: 0;
  border-bottom: none;
}
.modal-body {
  padding: 7px 0;
}
.modal-footer {
  display: none;
}
.modal {
  background: rgba(0, 0, 0, 0.6);
}
.btn-login {
  /*display:block!important;width:100%;*/
  margin-bottom: 30px;
}
span.radio-label {
  display: inline-block;
  margin: 7px 17px 17px 0;
}
.not-downloaded tr,
tr.not-downloaded {
  background: lightgreen !important;
}
.not-downloaded td span {
  color: #555;
}
.review table tr.not-downloaded td {
  border-bottom: 1px solid #fff;
}
@media (max-width: 991px) {
  .review table tr.not-downloaded td {
    border-bottom: none;
  }
  .parent-physician {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .review .row {
    /* margin: 0; */
    grid-gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
a.strong {
  font-weight: bold !important;
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot');
  src: url('../fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FFUnitWebPro';
  font-style: normal;
  font-weight: 400;
  src: local('FFUnitWebPro Regular'), local('FFUnitWebPro-Regular'),
    url('../fonts/ff-unit-web-pro-regular.woff2') format('woff2'),
    url('../fonts/ff-unit-web-pro-regular.woff') format('woff');
}

@font-face {
  font-family: 'FFUnitWebPro';
  font-style: normal;
  font-weight: 700;
  src: local('FFUnitWebPro Bold'), local('FFUnitWebPro-Bold'),
    url('../fonts/ff-unit-web-pro-bold.woff2') format('woff2'),
    url('../fonts/ff-unit-web-pro-bold.woff') format('woff');
}

/* @font-face {
	font-family: 'FFUnitWebPro';
	font-style: italic;
	font-weight: 400;
	src: local('FFUnitWebPro Italic'), local('FFUnitWebPro-Italic'),
		 url('../fonts/ff-unit-web-pro-italic.woff2') format('woff2'),
		 url('../fonts/ff-unit-web-pro-italic.woff') format('woff');
  } */

[class^='icon-main-'],
[class*=' icon-main-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-main-home:before {
  content: '\e901';
}
.icon-main-box:before {
  content: '\e900';
}
.icon-main-arrow-grey-left:before {
  content: '\e902';
}
.icon-main-arrow-grey-right:before {
  content: '\e903';
}
.icon-main-basket:before {
  content: '\e904';
}
.icon-main-calendar:before {
  content: '\e905';
}
.icon-main-close:before {
  content: '\e906';
}
.icon-main-combined-shape:before {
  content: '\e907';
}
.icon-main-edit:before {
  content: '\e908';
}
.icon-main-info::before {
  content: '\e909';
}
.icon-main-mail:before {
  content: '\e90a';
}
.icon-main-plus:before {
  content: '\e90b';
}
.icon-main-question-mark:before {
  content: '\e90c';
}
.icon-main-red-checkmark:before {
  content: '\e90d';
}
.icon-main-search-icon:before {
  content: '\e90e';
}
.icon-main-shape:before {
  content: '\e90f';
}
.icon-main-tel:before {
  content: '\e910';
}
.icon-main-dropdown-arrow:before {
  content: '\e911';
}
.icon-main-dropdown-arrow-2:before {
  content: '\e912';
}
.icon-main-arrow-top:before {
  content: '\e913';
}

a {
  color: #db2a33;
}
a:hover,
a:focus {
  color: #db2a33;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: 'Raleway', sans-serif; */
  font-weight: 300;
}
h1 {
  color: #333;
  /* font-family: 'Raleway', sans-serif;	 */
  font-size: 42px;
  font-weight: 300;
  margin: 0 0 8px;
}
@media (max-width: 991px) {
  h1 {
    font-size: 24px;
    margin: 0 0 3px;
    line-height: 32px;
  }
  h1 + p {
    font-size: 15px;
  }
  h1.register {
    margin-top: 30px;
  }
}
h2 {
  margin: 0 0 3px;
  font-size: 41px;
}
@media (max-width: 991px) {
  h2 {
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    margin-top: 30px;
  }
  a.btn-simple {
    padding: 6px 15px;
  }
}
h3 {
  color: #333;
  font-size: 20px;
  line-height: 30px;
  margin: 26px 0 24px;
}
h3 strong {
  display: block;
  font-weight: 700;
}
.btn {
  background: #e03e45;
  color: #fff;
  border: none;
  padding: 6px 15px 6px;
  font-size: 16px;
  border-radius: 3px;
  display: inline-block;
  vertical-align: top;
}
.btn:hover,
.btn:focus {
  color: #fff;
}
.btn-add {
  border-radius: 2px;
  border: 1px solid #dadada;
  background-color: #f2f2f2;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 270px;
  color: #e44142;
  font-size: 15px;
  padding: 6px 0 9px 15px;
}
.btn-add span {
  vertical-align: middle;
  margin-right: 21px;
}
.btn-add:hover,
.btn-add:focus,
.btn,
.btn.active,
.btn:focus,
.btn-border:hover,
.btn-border:focus {
  text-decoration: none;
  box-shadow: none;
}
input.btn {
  background: #e03e45;
  color: #fff;
  border: none;
  padding: 6px 15px 6px;
  display: inline-block;
  border-radius: 3px !important;
}
.btn-border {
  color: #e44142;
  border: #e44142 solid 1px;
  display: inline-block;
  vertical-align: top;
  padding: 6px 15px 6px;
  font-size: 15px;
  border-radius: 3px;
  background: #fff;
  margin-right: 5px;
}
.btn-search {
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  text-indent: -9999px;
  background: url(../img/search-icon.svg) no-repeat;
  position: absolute;
  left: 10px;
  top: 10px;
}
.search .holder-input {
  padding-top: 5px !important;
}
.search .holder-input input[type='text'] {
  border: none;
  background: none;
  width: 100%;
  padding: 7px 0 8px 38px !important;
  font-size: 15px;
}
.search .holder-input input[type='text']:focus,
.search .holder-input input[type='text'] {
  font-size: 15px;
}
p-table .search {
  margin-bottom: 20px;
}
.ui-dropdown-panel {
  top: 39px !important;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .search .holder-input input[type='text']:focus,
  .search .holder-input input[type='text'] {
    font-size: 15px;
  }
}
input[disabled].btn {
  color: #dcdcdc;
  border: solid 1px #dcdcdc;
  background: none;
}

.radio label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 15px;
  font-size: 13px;
}
.radio input[type='radio'] {
  display: none !important;
}
.radio label:before {
  content: '';
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 10px;
  position: absolute;
  left: 3px;
  bottom: 3px;
  background-color: #f5f5f5;
  border: 1px solid #dadada;
  border-radius: 100%;
}
.radio input[type='radio']:checked + label:after {
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  left: 8px;
  top: 12px;
  border-radius: 100%;
  background: #df3d44;
}
.radio p {
  color: #333;
  display: block;
  font-weight: 400;
  margin: 0 0 3px;
}
.radio {
  overflow: hidden;
}
.radio label {
  display: inline-block;
  vertical-align: top;
  margin: 0 23px 0 0;
  color: #333;
  font-size: 16px;
  line-height: 36px;
  padding-left: 36px;
}
.radio label:last-child {
  margin: 0;
}
.select {
  position: relative;
  margin: 0 0 16px;
}
.select .icon-main-dropdown-arrow {
  right: 13px;
  top: 16px;
  position: absolute;
  content: '';
  z-index: 1;
  pointer-events: none;
  color: #db2a33;
  font-size: 8px;
}

.select.active .icon-main-dropdown-arrow {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.select select {
  width: 100%;
  padding: 4px 30px 6px 5px;
  border-radius: 2px;
  border: 1px solid #dadada;
  background-color: #f5f5f5;
  color: #495057;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 36px;
}
.select select::-ms-expand {
  display: none;
}
.checkbox {
  margin: 0;
}
input[type='checkbox'] {
  display: none;
}
input[type='checkbox']:checked + label:before {
  content: '';
  background: #f5f5f5 url(../img/red-checkmark.svg) no-repeat 50% 50%;
}
input[type='text'].calendar {
  background: #f5f5f5 url(../img/calendar.svg) no-repeat 97% center;
  background-size: 16px 16px;
}
.checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  color: #333;
  font-size: 16px;
  padding: 0 0 0 34px;
}
.checkbox label strong {
  font-weight: 700;
  font-style: normal;
}
.checkbox label:before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  margin-top: -12px;
  position: absolute;
  left: 0;
  top: 50%;
  background: #f5f5f5;
  border: #d9d9d9 solid 1px;
}

.btn-simple {
  text-decoration: none;
  color: #888880;
  padding: 6px 20px;
  display: inline-block;
}
.btn-simple:hover {
  text-decoration: underline;
  color: #888880;
}
.btn-search.active {
  background: url(../img/ajax-loader.gif) no-repeat;
  cursor: default;
}
div.holder-pool {
  margin-bottom: 20px;
}
div.holder-pool p {
  margin: 15px 0;
}
h2.pool-name {
  font-size: 30px;
}
.nolink {
  cursor: default;
}

.slide-news div p:nth-child(2) {
  display: none;
}
.slide-news p {
  margin-bottom: 20px;
}
.slide-news a:hover,
.slide-news a:active,
.slide-news a.readmore:hover,
.slide-news a {
  text-decoration: none;
}
.slide-news a.readmore {
  text-decoration: underline;
}
div.slide-news {
  width: 90%;
}
div.slide-news img {
  width: 100%;
}

.breakingNews p {
  margin: 10px 0 40px 0;
}
.uppercase {
  text-transform: uppercase;
}
.loading-points {
  width: 128px;
  height: 31px;
  background: url(../img/three-points.svg) no-repeat;
}
.loading-globe {
  width: 70px;
  height: 70px;
  background: url(../img/small.gif) no-repeat;
}
.loading-points-fullscreen {
  width: 100%;
  height: 100%;
  background: url(../img/small.gif) 50% no-repeat;
  line-height: 3em;
}
.form-control {
  background: #f5f5f5;
  border: 1px solid #dadada;
  box-shadow: none;
  border-radius: 2px;
}

.help {
  float: right;
  color: #333;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  margin: -12px 0 0;
}
@media (max-width: 767px) {
  .help {
    display: none;
  }
}
.help span {
  color: #db2a33;
  font-size: 24px;
  margin: 0 0 0 16px;
  vertical-align: middle;
}
.help:hover,
.help:active {
  text-decoration: none;
  color: #db2a33;
}

/* div.pool-container > div {
  text-align: center;
  margin-bottom: 30px;
} */
div.pool-container figure {
  display: inline-block;
  background: #f2f2f2;
  width: 155px;
  height: 155px;
  line-height: 152px;
  border-radius: 100%;
  text-align: center;
  margin: 0 0 13px;
  padding: 0 0 0 6px;
}

div.pool-container figure span svg {
  vertical-align: middle;
}

em {
  font-style: italic;
  /*background: #e44142;
	color: #fff;*/
  border: 1px solid #888;
  border-radius: 2px;
  padding: 2px 4px 0 4px;
  margin: 0 2px;
}

div.pool-container div.info {
  margin-bottom: 15px;
}
div.pool-container a.full {
  width: 100%;
}
div.pool-container a.part {
  width: 85%;
}
div.pool-container a.i {
  padding-left: 14px;
  width: 31px;
  margin-left: 15px;
  margin-right: 5px;
}
div.pool-container a.x {
  padding-left: 12px;
  width: 31px;
  margin-left: 15px;
  margin-right: 5px;
}

a.pool-1 {
  background: #a02116;
} /*Archimed Life PDF*/
a.pool-2 {
  background: #ec6978;
} /*Newborn Screening*/
a.pool-3 {
  background: #5359a5;
} /*Lysodiagnostics DACH*/
a.pool-4 {
  background: #7cb74d;
} /*SMA*/
a.pool-5 {
  background: #00529b;
} /*Biomarin*/
a.pool-6 {
  background: #5359a5;
} /*Lysodiagnostics EU*/

div.pool-container h4 {
  min-height: 40px;
}
div.pool-container p.sub {
  min-height: 68px;
}

div#account h1.account-edit {
  margin-bottom: 0;
}

td.review-download {
  min-width: 180px;
  max-width: 180px;
}

.search .holder-input input[type='text'] {
  padding: 7px 0 0 30px;
}
.search .holder-input {
  padding: 4px;
  margin-right: 10px;
}
.search .holder-select {
  margin-right: 10px;
}
.btn-search {
  top: 15px;
}
.holder-select {
  border: 1px solid #dadada;
  border-radius: 2px;
}
.holder-select.p-md-4 .ui-inputtext {
  width: 91% !important;
}
.holder-select.p-md-2 .ui-inputtext {
  width: 86% !important;
}
.review .holder-select {
  text-align: left !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 1rem;
  padding: 0.5rem;
}

p-dropdownitem span {
  margin: 4px 0;
  display: inline-block;
}
p-dropdownitem span:hover {
  background: #eee;
}

/*physicianstable th {
	border-top: 1px solid #dadada;
}

physicianstable th:nth-child(1), physicianstable th:last-child {
	border: 0
}
physicianstable tr:nth-child(1) th:last-child {
	border-left: 1px solid #dadada;
}
physicianstable th:nth-child(1) {
	border-right: 1px solid #dadada;
}*/
physicianstable th:nth-child(2) input {
  padding-left: 24px !important;
  background: url('../img/search-icon.svg') 0 8px no-repeat;
}
.checkbox-form questions .holder-radio p {
  margin-top: 5px;
}
.uppercase {
  text-transform: uppercase;
}
.parent-physician {
  max-width: 320px;
}
.hidden-form {
  display: none;
  position: absolute;
  top: -10000px;
}
[hidden] {
  display: none !important;
}

details summary {
  color: #db2a33;
}

details[open] summary ~ * {
  animation: sweep ease 1s;
  /* animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1); */
}

@keyframes sweep {
  0% {
    opacity: 0;
    /* transform: translateX(-10px); */
  }
  100% {
    opacity: 1;
    /* transform: translateX(0); */
  }
}
